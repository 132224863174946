import { getZipInfo } from "@/api/business.api";

const state = () => ({
  sidebar: false,
  sidebarMinimize: true,
});

const getters = {
  isSidebarOpen: state => state.sidebar,
}

const actions = {
  sidebarShow({ commit }, value) {
    commit('SIDEBAR_SHOW', value)
  },
  sidebarMinimize({ commit }, value) {
    commit('SIDEBAR_MINIMIZE', value)
  },
  sidebarToggle({ commit }) {
    commit('SIDEBAR_TOGGLE')
  },
  async getZipInfo({ commit }, { zip, country, lang }) {
    return getZipInfo({ zip, country, lang })
      .then(response => {
        return response.data
      })
      .catch(error => {
        console.error(error)
        return null
      })
  }
};

const mutations = {
  SIDEBAR_SHOW(state, value) {
    state.sidebar = value
  },
  SIDEBAR_MINIMIZE(state, value) {
    state.sidebarMinimize = value
  },
  SIDEBAR_TOGGLE(state) {
    state.sidebarMinimize = !state.sidebarMinimize
  },
  RESET_ALL(state) {
    state.sidebar = false
    state.sidebarMinimize = true
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
