import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import CoreuiVue, { CIcon } from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import VueMeta from 'vue-meta'
import VueI18n from 'vue-i18n'
// import { i18n } from './i18n' // for dynamic i18n
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueWindowSize from 'vue-window-size'
import VueNativeSock from 'vue-native-websocket'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import CoolLightBox from 'vue-cool-lightbox'
import * as Sentry from "@sentry/vue";
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import '@/assets/scss/base.scss';
// import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
// import "primevue/resources/themes/saga-purple/theme.css";

import './permission'
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice'

Vue.config.productionTip = false

Vue.use(PrimeVue)
Vue.use(ToastService)
Vue.use(VueMeta)
Vue.use(CoreuiVue)
Vue.component('CIcon', CIcon)
Vue.use(VueI18n)

Vue.use(VueLodash, { lodash: lodash })

Vue.use(VueWindowSize)
Vue.use(VueMoment, {
    moment
})
Vue.use(CoolLightBox)

import locales from './locales/index'
const navigatorLanguage = locales[navigator.language] ? navigator.language : 'en'
const i18n = new VueI18n({
  locale: localStorage.lang ? localStorage.lang : navigatorLanguage,
  fallbackLocale: 'en',
  formatFallbackMessages: true,
  messages: locales
});

Vue.use(VueNativeSock, process.env.VUE_APP_SOCKET_URL, {
  reconnection: true,
  reconnectionAttempts: 5,
  reconnectionDelay: 3000
})

Sentry.init({
  Vue,
  dsn: "https://26c7f5de81d5446bbab444056517941d@sentry.cardo.it/9",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      // tracingOrigins: ["localhost", "10.0.0.183", 'admin.instashop.io', /^\//],
    }),
    new Sentry.Replay()
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});


new Vue({
  router,
  store,
  icons,
  i18n,
  render: h => h(App)
}).$mount('#app')
