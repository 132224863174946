import axios from 'axios';
import store from '@/store';

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

httpClient.interceptors.request.use(config => {
  if (localStorage.token) {
    config.headers['Authorization'] = localStorage.token
  }
  return config
}, error => {
  console.log(error.response);
  Promise.reject(error)
})

httpClient.interceptors.response.use(function (response) {
  return response;
}, error => {
  console.log(error.response);
  if (error.response && error.response.status && error.response.status === 401 && error.response.statusText === 'Unauthorized') {
    store.dispatch('user/logoutUser', { redirect: true })
  }
  if (error.response && error.response.status && error.response.status === 403) {
    store.dispatch('user/forbiddenAccess')
  }
  if (error.response && error.response.status && error.response.status === 502) {
    store.dispatch('user/logoutUser', { redirect: true })
  }
  return Promise.reject(error);
});

export default httpClient;
