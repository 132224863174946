import httpClient from './httpClient';

const END_POINT = '/api/business';

const getDashboard = () => httpClient.get(`${END_POINT}/main-page`);
const getZipInfo = ({ zip, country, lang }) => httpClient.get(`${END_POINT}/zip-info`,
  {
    params: { zip, country, lang }
  });

export { getDashboard, getZipInfo };
