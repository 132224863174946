<template>
  <div id="app">
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
const EmptyLayout = () => import("@/layouts/EmptyLayout.vue");
const MainLayout = () => import("@/layouts/MainLayout.vue");
const FormLayout = () => import("@/layouts/FormLayout.vue");

export default {
  name: 'App',
  computed: {
    layout() {
      return `${(this.$route.meta.layout || 'empty')}-layout`
    }
  },
  components: {
    MainLayout,
    FormLayout,
    EmptyLayout
  },
  metaInfo() {
    const routeTitle = this.$route.meta && this.$route.meta.title ? this.$route.meta.title : this.$route.name
    return {
      title: this.$t('title.' + (routeTitle || 'home')),
      titleTemplate: this.$t('title._template'),
      link: [{
        rel: 'stylesheet',
        href: 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&family=Oswald:wght@500&display=swap'
      }]
    }
  }
}
</script>
