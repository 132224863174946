<template>
  <div class="presentation-heading__wrapper" :class="{ center }">
    <IHeading
      class="presentation-heading"
      :class="{ uppercase }"
      :level="level"
      :title="title"
      :hint="hint"
      h-tag
    />
  </div>
</template>

<script>
import { IHeading } from 'instashop-ui';

export default {
  name: 'PresentationHeading',
  components: {
    IHeading
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    hint: {
      type: [String, Object],
      default: () => ''
    },
    level: {
      type: Number,
      default: 1
    },
    uppercase: {
      type: Boolean,
      default: true
    },
    center: {
      type: Boolean,
      default: false
    }
  },
}
</script>

<style scoped lang="scss">
.presentation-heading {
  --heading-font-family: Oswald, sans-serif;
  --heading-font-weight: 500;
  --h1-font-size: 3.75rem;
  --h2-font-size: 2.625rem;
  --h3-font-size: 1.875rem;
  --heading-line-height: 1;
  display: inline-block;
  margin: var(--heading-margin, #{"var(--spacer-xs) 0 var(--spacer-base)"});

  &__wrapper {
    &.center {
      --heading-justify-content: center;
      text-align: center;
    }
  }

  ::v-deep .heading--title {
    display: inline;
  }

  ::v-deep .i-tooltip {
    display: inline-block;
    vertical-align: top;
  }

  &.uppercase {
    ::v-deep .heading--title {
      text-transform: uppercase;
    }
  }

  @media screen and (max-width: 767px) {
    --h1-font-size: 2.75rem;
    --h2-font-size: 1.75rem;
  }
}
</style>