<template>
  <div class="error-page">
    <div class="error-page--header">
      <PresentationHeading :title="$t(title)" :level="2" />
    </div>
    <div class="error-page--body">
      <p v-html="$t(description)" />
    </div>
    <div class="error-page--footer">
      <IButton :link="{ name: 'dashboard' }" class="sf-button--link">
        {{ $t('error.go_home') }}
      </IButton>
    </div>
  </div>
</template>
<script>
import PresentationHeading from "@/components/atoms/PresentationHeading.vue";
import { IHeading, IButton } from "instashop-ui";

export default {
  name: 'ErrorPage',
  components: {
    PresentationHeading,
    IHeading,
    IButton
  },
  props: {
    title: {
      type: String,
      default: 'title.error'
    },
    description: {
      type: String,
      default: 'error.description'
    }
  }
}
</script>

<style lang="scss">
.error-page {
  &--header {
    --heading-margin: 0;
    margin-bottom: var(--spacer-sm);
    border-bottom: 1px solid var(--theme-light);
    padding: var(--spacer-base) var(--spacer-base) var(--spacer-sm);
  }

  &--body {
    padding: var(--spacer-xs) var(--spacer-base);
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacer-xs);
    flex-direction: column;
    align-items: center;

    p {
      white-space: pre-line;
    }
  }

  &--footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: var(--spacer-xs) var(--spacer-base) var(--spacer-base);

    .sf-button--link {
      width: auto;
    }
  }
}
</style>
