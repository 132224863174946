import Vue from 'vue'
import VueRouter from 'vue-router'

import Parent from '@/views/Parent'
import ErrorPage from '@/views/ErrorPage.vue'

Vue.use(VueRouter)

export const routes = [
  {
    path: '/',
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */'@/views/Dashboard'),
    meta: {
      breadcrumb: 'dashboard',
      layout: 'main'
    },
  },
  {
    path: '/brands',
    component: Parent,
    meta: {
      breadcrumb: 'brand-list',
      roles: ['admin'],
      layout: 'main'
    },
    children: [
      {
        path: '',
        name: 'brand-list',
        component: () => import(/* webpackChunkName: "brands" */ '@/views/Brands/List'),
        meta: {
          roles: ['admin'],
          layout: 'main'
        }
      },
      {
        path: ':code',
        name: 'admin-brand-cabinet',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Cabinet.vue'),
        meta: {
          title: 'brand-cabinet',
          breadcrumb: 'brand-cabinet',
          roles: ['admin'],
          parent: 'brand-list',
          layout: 'main'
        },
      },
      {
        path: ':code/get-started',
        name: 'admin-brand-get-started',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/GetStarted'),
        meta: {
          title: 'brand-get-started',
          breadcrumb: 'brand-get-started',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/edit',
        name: 'admin-brand-edit',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Edit'),
        meta: {
          title: 'brand-edit',
          breadcrumb: 'brand-edit',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/products',
        name: 'admin-brand-products',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Products'),
        meta: {
          title: 'brand-products',
          breadcrumb: 'brand-products',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/sales',
        name: 'admin-brand-sales',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Sales.vue'),
        meta: {
          title: 'brand-sales',
          breadcrumb: 'brand-sales',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/transactions',
        name: 'admin-brand-transactions',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Transactions.vue'),
        meta: {
          title: 'brand-transactions',
          breadcrumb: 'brand-transactions',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/shipments',
        name: 'admin-brand-shipments',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Shipments.vue'),
        meta: {
          title: 'brand-shipments',
          breadcrumb: 'brand-shipments',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: ':code/shipments/:id',
        name: 'admin-brand-shipment',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Shipment.vue'),
        meta: {
          title: 'brand-shipment',
          breadcrumb: 'brand-shipment',
          roles: ['admin'],
          parent: 'admin-brand-shipments',
          layout: 'main'
        },
      },
      {
        path: ':code/payouts',
        name: 'admin-brand-payouts',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Payouts'),
        meta: {
          title: 'brand-payouts',
          breadcrumb: 'brand-payouts',
          roles: ['admin'],
          parent: 'admin-brand-cabinet',
          layout: 'main'
        },
      }
    ]
  },
  {
    path: '/cabinet',
    component: Parent,
    meta: {
      breadcrumb: 'brand-cabinet',
      roles: ['supplier'],
      layout: 'main'
    },
    children: [
      {
        path: '',
        name: 'brand-cabinet',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Cabinet'),
        meta: {
          roles: ['supplier'],
          layout: 'main'
        },
      },
      {
        path: 'get-started',
        name: 'brand-get-started',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/GetStarted'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: 'edit',
        name: 'brand-edit',
        component: () => import(/* webpackChunkName: "brand-cabinet" */ '@/views/Brands/Edit'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        },
      },
      {
        path: 'products',
        name: 'brand-products',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Products'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        }
      },
      {
        path: 'sales',
        name: 'brand-sales',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Sales.vue'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        }
      },
      {
        path: 'transactions',
        name: 'brand-transactions',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Transactions.vue'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        }
      },
      {
        path: 'shipments',
        name: 'brand-shipments',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Shipments.vue'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        }
      },
      {
        path: 'shipments/:id',
        name: 'brand-shipment',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Shipment.vue'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-shipments',
          layout: 'main'
        }
      },
      {
        path: 'payouts',
        name: 'brand-payouts',
        component: () => import(/* webpackChunkName: "brand-cabinet" */'@/views/Brands/Payouts.vue'),
        meta: {
          roles: ['supplier'],
          parent: 'brand-cabinet',
          layout: 'main'
        }
      },
    ]
  },
  {
    path: '/sites',
    component: Parent,
    meta: {
      breadcrumb: 'sites',
      roles: ['deployer'],
      layout: 'main'
    },
    children: [
      {
        path: '',
        name: 'sites',
        component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/List.vue'),
        meta: {
          roles: ['deployer'],
          layout: 'main'
        },
      },
      {
        path: 'add',
        name: 'site-add',
        component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/Add.vue'),
        meta: {
          parent: 'sites',
          roles: ['deployer'],
          layout: 'main'
        },
      },
      {
        path: ':id',
        name: 'site',
        component: () => import(/* webpackChunkName: "sites" */ '@/views/Sites/Details.vue'),
        meta: {
          parent: 'sites',
          roles: ['deployer'],
          layout: 'main'
        },
      },
      {
        path: ':id/edit',
        name: 'site-edit',
        redirect: ':id',
        meta: {
          roles: ['deployer'],
          layout: 'main'
        },
      }
    ]
  },
  {
    path: '/site/add',
    redirect: 'sites/add'
  },
  {
    path: '/site/:id',
    redirect: 'sites/:id'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Login'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/Register'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/forgot',
    name: 'Forgot',
    component: () => import(/* webpackChunkName: "auth" */ '@/views/ForgotPass'),
    meta: {
      layout: 'empty'
    }
  },
  {
    path: '/signin',
    name: 'Sign In',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/SignIn.vue'),
    meta: {
      layout: 'form',
      title: 'sign-in'
    }
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/SignUp.vue'),
    meta: {
      layout: 'form',
      title: 'sign-up'
    }
  },
  {
    path: '/onboarding',
    name: 'Onboarding',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/Onboarding.vue'),
    meta: {
      layout: 'form',
      title: 'onboarding'
    }
  },
  {
    path: '/welcome',
    name: 'Welcome',
    component: () => import(/* webpackChunkName: "onboarding" */ '@/views/Welcome.vue'),
    meta: {
      layout: 'form',
      title: 'welcome'
    }
  },
  {
    path: '/401',
    name: '401',
    component: ErrorPage,
    props: {
      title: 'title._401',
      description: 'error.unauthorized'
    },
    meta: {
      layout: 'form',
      title: '_401'
    }
  },
  {
    path: '/403',
    name: '403',
    component: ErrorPage,
    props: {
      title: 'title._403',
      description: 'error.forbidden'
    },
    meta: {
      title: '_403',
      layout: 'form'
    }
  },
  {
    path: '/404',
    name: '404',
    component: ErrorPage,
    props: {
      title: 'title._404',
      description: 'error.not_found'
    },
    meta: {
      layout: 'form',
      title: '_404'
    }
  },
  {
    path: '*',
    redirect: '404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth'
      }
    } else if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0, behavior: 'smooth' }
    }
  }
})

export default router
