import router, { routes } from '@/router'
import { getInstagramProfile } from '@/api/users.api';
import {
  loginUser,
  loginByToken,
  registerUser,
  changePassword,
  forgotPassword,
  signupUser,
  onboardUser,
  signinUser,
  updateUser,
} from "@/api/auth.api";

function flatRoutes(routes) {
  return (routes || []).flatMap(route => {
    const { children, ...restRoute } = route

    return children ? [restRoute, ...flatRoutes(children)] : [restRoute]
  })
}

function sendToParent(message) {
  // console.log('send to parent', message)
  if (window && window.parent) {
    window.parent.postMessage(message, '*')
  }
}

const state = () => ({
  token: localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : null,
  user: null,
  changePasswordModal: false,
  routes: flatRoutes(routes),
  instagram: {}
});

const getters = {
  role: state => state.user ? state.user.role : null,
  roles: state => state.user ? (state.user.roles || []) : [],
  getUser: state => state.user,
  routes: state => state.routes,
  hasRole: state => (role, adminAsFallback = true) => {
    return state.user && state.user.roles && (state.user.roles.includes(role)
      || (adminAsFallback && (state.user.roles.includes('admin') || state.user.roles.includes('su'))))
  },
  isUserCanView: state => ({ name, path }) => {
    if (!state.user || !state.user.roles) {
      return false
    }
    if (state.user.roles.includes('admin') || state.user.roles.includes('su')) {
      return true
    }
    const route = state.routes.find(route => name ? route.name === name : route.path === path)
    if (!route || !route.meta || !route.meta.roles) {
      return true
    }
    return !!route.meta.roles.some(role => state.user.roles.includes(role))
  },
  changePasswordModal: state => state.changePasswordModal
};

const actions = {
  async loginHelper({ dispatch }, { loginFunction, loginData }) {
    if (!loginFunction || !loginData) {
      return Promise.reject(new Error('Login function or data is not provided'))
    }
    try {
      const { data } = await loginFunction(loginData)
      dispatch('setToken', data.token) // jwt token
      dispatch('setUser', data.user)

      if ((data.user.roles || []).indexOf('supplier') !== -1) {
        data.brand = await dispatch('brands/loadUserBrand', undefined, { root: true }).catch((e) => {
          console.error('Error loading brand info', e)
          return null
        })
      }

      sendToParent({
        action: 'auth',
        status: 'ok',
        shop: data && data.brand ? data.brand.domain : (loginData.shop || ''),
        token: data && data.brand ? data.brand.token : (loginData.token || ''),
        email: data && data.user ? data.user.email : (loginData.username || '')
      })
    } catch (error) {
      console.error('[ERROR] Login user', error)
      sendToParent({
        action: 'auth',
        status: 'error',
        shop: loginData.shop || '',
        token: '',
        email: loginData.username || ''
      })
      return Promise.reject(error)
    }
  },
  loginByToken({ dispatch }, { token }) {
    return dispatch('loginHelper', {
      loginFunction: loginByToken,
      loginData: { token }
    })
  },
  loginUser({ dispatch }, { username, password, shop }) {
    return dispatch('loginHelper', {
      loginFunction: loginUser,
      loginData: { username, password, shop }
    })
  },
  resetPassword({ commit }, { email }) {
    return forgotPassword({ email, reset: true })
      .catch((error) => {
        console.error('[ERROR] Forgot password', error)
        return Promise.reject(error)
      })
  },
  setNewPassword({ commit }, { email, code, password, password_confirmation }) {
    return forgotPassword({ email, code, password, password_confirmation, set_new: true })
      .catch((error) => {
        console.error('[ERROR] Create new password', error)
        return Promise.reject(error)
      })
  },
  signinUser({ commit, dispatch }, { username, ...fields }) {
    return signinUser({ ...fields, username })
      .then(({ data }) => data)
  },
  signupUser({ commit, dispatch }, { phone, email, terms, type, ...fields }) {
    return signupUser({ phone, email, terms, type, ...fields })
      .then(({ data }) => data)
  },
  onboardUser({ commit, dispatch }, { phone, email, terms, type, name, brand, instagram }) {
    return onboardUser({ phone, email, terms, type, name, brand, instagram })
      .then(({ data }) => data)
  },
  updateUser({ commit, dispatch }, { ...fields }) {
    return updateUser(fields)
      .then(({ data }) => data)
  },
  registerUser({ commit, dispatch }, { email, shop, ...fields }) {
    return registerUser({ ...fields, email, shop })
      .then(({ data }) => {
        // console.log('user register', data)
        sendToParent({
          action: 'register',
          status: 'ok',
          shop: (data && data.user && data.user.brand ? data.user.brand.domain : '') || shop,
          token: data && data.user ? data.user.token : '',
          email: (data && data.user ? data.user.email : '') || email
        })
        return data
      })
      .catch((error) => {
        console.error('[ERROR] Register user', error)
        sendToParent({
          action: 'register',
          status: 'error',
          shop: shop || '',
          token: '',
          email: email || ''
        })
        return Promise.reject(error)
      })
  },
  setToken({ commit }, jwtToken) {
    if (localStorage) {
      localStorage.setItem('token', jwtToken)
    }
    commit('SET_TOKEN', jwtToken)
  },
  setUser({ commit }, user) {
    commit('SET_USER', user)
  },
  logoutUser({ commit, rootGetters, getters }, { shop = '', token = '', redirect = false } = {}) {
    const brand = rootGetters['brands/userBrand']
    sendToParent({
      shop: brand ? brand.domain : (shop || ''),
      action: 'unauth',
      status: 'ok',
      token: brand ? brand.token : (token || ''),
      email: getters.getUser ? getters.getUser.email : ''
    })
    commit('brands/RESET_ALL', null, { root: true })
    commit('site/RESET_ALL', null, { root: true })
    commit('main/RESET_ALL', null, { root: true })
    commit('LOG_OUT')
    if (redirect && window) {
      window.location.href = '/login'
      // router.push({ name: 'login' })
      // router.go()
    }
  },
  forbiddenAccess({}) {
    return router.push({ name: '403' })
  },
  instagramProfile({ state, commit }, instagram) {
    instagram = (instagram || '').replace('@', '')
    if (state.instagram.hasOwnProperty(instagram)) {
      return state.instagram[instagram]
    }
    return getInstagramProfile(instagram)
      .then(({ data }) => {
        commit('SET_INSTAGRAM', { username: instagram, profile: data })
        return data
      })
      .catch(error => {
        console.error('[ERROR] Instagram profile', error)
        return null
      })
  },
  changePasswordModal({ commit }, value) {
    commit('CHANGE_PASSWORD_MODAL', value)
  },
  changePassword({ commit }, { oldPassword, newPassword }) {
    return changePassword({ oldPassword, newPassword })
      .catch((error) => {
        console.error('[ERROR] Change password', error)
        return Promise.reject(error)
      })
  }
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_USER(state, user) {
    state.user = user
  },
  LOG_OUT(state) {
    if (localStorage) {
      localStorage.removeItem('token')
    }
    state.token = null
    state.user = null
    state.instagram = {}
  },
  SET_INSTAGRAM(state, { username, profile }) {
    state.instagram[username] = profile
  },
  CHANGE_PASSWORD_MODAL(state, value) {
    state.changePasswordModal = value
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
