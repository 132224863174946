import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user';
import main from './modules/main';
import site from './modules/site';
import brands from './modules/brands';
import { AdminPanelStore } from 'admin-panel'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    main,
    site,
    brands,
    menu: AdminPanelStore
  }
})
