import httpClient from './httpClient';

const END_POINT = '/api/users';

const getUser = () => httpClient.get(`${END_POINT}/profile`);
const getInstagramProfile = (login) => httpClient.get(`${END_POINT}/instagram/${login}`)
const connectUserStripe = (data) => httpClient.post(`${END_POINT}/stripe-connect`, data, {
  headers: { 'Content-Type': 'application/json' }
});

export { getUser, getInstagramProfile, connectUserStripe };
