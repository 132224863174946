import httpClient from './httpClient';

const END_POINT = '/api/sites';

const getSites = (params) => httpClient.get(`${END_POINT}/list`, {params});
const getBrands = () => httpClient.get(`${END_POINT}/brands`);
const getSocials = () => httpClient.get(`${END_POINT}/socials`);
const getIndustries = () => httpClient.get(`${END_POINT}/industries`);
const getPlatforms = () => httpClient.get(`${END_POINT}/platforms`);
const getSite = (id) => httpClient.get(`${END_POINT}/${id}`)
const getLatestBuild = () => httpClient.get(`${END_POINT}/latest`)
const toggleLatestBuild = (data) => httpClient.post(`${END_POINT}/latest`, data, {
    headers: { 'Content-Type': 'application/json' }
})
const updateSite = (id, data) => httpClient.post(`${END_POINT}/${id}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
});
const patchSite = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data, {
    headers: { 'Content-Type': 'application/json' }
});
const createSite = (formData) => httpClient.post(`${END_POINT}/create`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
})
const refreshSiteData = id => httpClient.post(`${END_POINT}/${id}/refresh`);
const importSiteData = id => httpClient.post(`${END_POINT}/${id}/import`);
const siteToProd = (siteId) => httpClient.post(`${END_POINT}/${siteId}/to-prod`)

const deleteSite = (id) => httpClient.delete(`${END_POINT}/${id}`);

const addSlide = (siteId, data) => httpClient.post(`${END_POINT}/${siteId}/slider`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
})

const updateSlide = (siteId, slideId, data) => httpClient.post(`${END_POINT}/${siteId}/slider/${slideId}`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
})

const deleteSlide = (siteId, slideId) => httpClient.delete(`${END_POINT}/${siteId}/slider/${slideId}`)

export {
    getSite,
    getSites,
    updateSite,
    patchSite,
    createSite,
    deleteSite,
    refreshSiteData,
    importSiteData,
    getBrands,
    getIndustries,
    getPlatforms,
    getSocials,
    getLatestBuild,
    toggleLatestBuild,
    addSlide,
    updateSlide,
    deleteSlide,
    siteToProd,
};
