import {
    cibFacebook,
    cibTwitter,
    cibLinkedin,
    cibFlickr,
    cibTumblr,
    cibXing,
    cibGithub,
    cibStackoverflow,
    cibYoutube,
    cibDribbble,
    cibInstagram,
    cibPinterest,
    cibVk,
    cibYahoo,
    cibBehance,
    cibReddit,
    cibVimeo,
    cibCcMastercard,
    cibCcVisa,
    cibStripe,
    cibPaypal,
    cibGooglePay,
    cibCcAmex,
    cibCoveralls,
    cibCashapp,
    cibInternetExplorer
  } from '@coreui/icons'
  import {
    cifUs,
    cifBr,
    cifIn,
    cifFr,
    cifEs,
    cifPl
  } from '@coreui/icons'
  import {
    cilArrowRight,
    cilBan,
    cilBasket,
    cilBell,
    cilCalculator,
    cilCalendar,
    cilCloudDownload,
    cilChartPie,
    cilCheck,
    cilChevronBottom,
    cilChevronTop,
    cilCheckCircle,
    cilCommentSquare,
    cilCursor,
    cilDrop,
    cilDollar,
    cilEnvelopeClosed,
    cilEnvelopeOpen,
    cilEuro,
    cilGlobeAlt,
    cilGrid,
    cilFile,
    cilJustifyCenter,
    cilLaptop,
    cilLayers,
    cilLibraryBuilding,
    cilLightbulb,
    cilList,
    cilLocationPin,
    cilLockLocked,
    cilMagnifyingGlass,
    cilMoon,
    cilMenu,
    cilOptions,
    cilPencil,
    cilPeople,
    cilPuzzle,
    cilSettings,
    cilShieldAlt,
    cilSpeech,
    cilSpeedometer,
    cilStar,
    cilTask,
    cilUser,
    cilUserFemale,
    cilUserFollow,
    cilXCircle,
    cilTruck,
    cilTerminal,
    cilGraph,
    cilExternalLink,
    cilRecycle,
    cilImage,
    cilFingerprint,
    cilDescription,
    cilSync,
    cilDelete
  } from '@coreui/icons'


  export const iconsSet = Object.assign(
    {},
    {
      cilSync,
      cilDescription,
      cilArrowRight,
      cilBan,
      cilBasket,
      cilBell,
      cilCalculator,
      cilCalendar,
      cilCloudDownload,
      cilChartPie,
      cilCheck,
      cilChevronBottom,
      cilChevronTop,
      cilCheckCircle,
      cilCommentSquare,
      cilCursor,
      cilDrop,
      cilDollar,
      cilEnvelopeClosed,
      cilEnvelopeOpen,
      cilEuro,
      cilGlobeAlt,
      cilGrid,
      cilFile,
      cilJustifyCenter,
      cilLaptop,
      cilLayers,
      cilLibraryBuilding,
      cilLightbulb,
      cilList,
      cilLocationPin,
      cilLockLocked,
      cilMagnifyingGlass,
      cilMoon,
      cilMenu,
      cilOptions,
      cilPencil,
      cilPeople,
      cilPuzzle,
      cilSettings,
      cilShieldAlt,
      cilSpeech,
      cilSpeedometer,
      cilStar,
      cilTask,
      cilUser,
      cilUserFemale,
      cilUserFollow,
      cilXCircle,
      cilTruck,
      cilTerminal,
      cilGraph,
      cilExternalLink,
      cilDelete,
      cilRecycle,
      cilImage,
      cilFingerprint
    },
    {
      cifUs,
      cifBr,
      cifIn,
      cifFr,
      cifEs,
      cifPl
    },
    {
      cibFacebook,
      cibTwitter,
      cibLinkedin,
      cibFlickr,
      cibTumblr,
      cibXing,
      cibGithub,
      cibStackoverflow,
      cibYoutube,
      cibDribbble,
      cibInstagram,
      cibPinterest,
      cibVk,
      cibYahoo,
      cibBehance,
      cibReddit,
      cibVimeo,
      cibCcMastercard,
      cibCcVisa,
      cibStripe,
      cibPaypal,
      cibGooglePay,
      cibCcAmex,
      cibCoveralls,
      cibCashapp,
      cibInternetExplorer
    }
  )
