import {
  getBrands,
  getIndustries,
  getPlatforms,
  getSocials,
  getSite,
  getSites,
  getLatestBuild,
  toggleLatestBuild,
  updateSite,
  patchSite,
  addSlide,
  updateSlide,
  deleteSlide,
  refreshSiteData,
  importSiteData,
  deleteSite,
  siteToProd,
} from '@/api/sites.api';
import Vue from 'vue';

const defaultNavbarDesign = {
  type: 'gradient',
  background: '',
  solid_blur: false,
  gradient_to: '',
  gradient_angle: 45,
  texture_preview: '',
  texture_file: null, // for file object
  sticky: true,
  sticky_hideable: true,
  color: '',
  color_active: '',
  transparent: false,
  color_on_light: '',
  color_on_dark: ''
}

const defaultBottomNavbarDesign = {
  copy: true,
  hide_label: false,
  type: 'gradient',
  background: '',
  solid_blur: false,
  gradient_to: '',
  gradient_angle: 45,
  texture_preview: '',
  texture_file: null, // for file object
  color: '',
  color_active: '',
  transparent: false,
}

const defaultButtonsDesign = {
  type: 'gradient',
  background: '',
  gradient_to: '',
  gradient_angle: 90,
  texture_preview: '',
  texture_file: null, // for file object
  color: '',
  color_active: '',
  border_radius: '1.875rem'
}

const defaultColors = {
  primary: '#a18dd7',
  secondary: '#ff8383',
  info: '#0468db',
  price: '#018937',
  success: '#018937',
  warning: '#ecc713',
  danger: '#d12727'
}

const defaultSiteForm = {
  id: '',
  api_id: '',
  domain: '',
  name: '',
  domain_first_level: '',
  influencer_photo: '',
  influencer_followers: 0,
  influencer_name: '',
  influencer_phone: '',
  influencer_instagram: '',
  industry: '',
  launch_mode_site: '',
  launch_mode_instagram: '',
  launch_mode_android: '',
  launch_mode_flutter: '',
  launch_mode_unity: '',
  username: '',
  instagram: '',
  phone: '',
  primary_color: '#6494b2',
  primary_gradient_color: '',
  secondary_color: '#f2f2f2',
  danger_color: '#d12727',
  success_color: '#018937',
  info_color: '#0468db',
  warning_color: '#ecc713',
  price_color: '',
  header_color: '',
  icons_color: '',
  colors: { ...defaultColors },
  logo: null,
  logo_png: null,
  logo_svg: null,
  logo_file: null,
  brands: {
    en: []
  },
  feeds: {},
  stages: {},
  industries: [],
  platforms: [],
  socials: [],
  banners: {},
  sliders: [],
  langs: ['en'],
  base_lang: 'en',
  design_navbar: { ...defaultNavbarDesign },
  design_bottom_navbar: { ...defaultBottomNavbarDesign },
  design_buttons: { ...defaultButtonsDesign },
  splash: '',
  splash_desktop: '',
  defaults: {}
}

const convertSlideToFormData = (slide, langs) => {
  const form = new FormData()
  if (!(slide.new || slide.is_new)) {
    form.append('id', slide.id)
  }
  form.append('bg', slide.bg)
  form.append('sort', slide.sort)
  form.append('title_system', slide.title_system || '')
  for (const lang of langs) {
    if (!(slide.picture_mobile[lang] || slide.picture_desktop[lang])) {
      continue;
    }
    form.append('langs[]', lang)
    form.append('title[' + lang + ']', slide.title[lang] || '')
    form.append('subtitle[' + lang + ']', slide.subtitle[lang] || '')
    form.append('text_over_title[' + lang + ']', slide.text_over_title[lang] || '')
    if (slide.file_desktop[lang]) {
      if (slide.file_desktop[lang] instanceof File) {
        form.append('file_desktop[' + lang + ']', slide.file_desktop[lang], slide.file_desktop[lang].name)
      } else {
        form.append('file_desktop[' + lang + ']', slide.file_desktop[lang]) // could be "delete" string
      }
    }
    if (slide.file_mobile[lang]) {
      if (slide.file_mobile[lang] instanceof File) {
        form.append('file_mobile[' + lang + ']', slide.file_mobile[lang], slide.file_mobile[lang].name)
      } else {
        form.append('file_mobile[' + lang + ']', slide.file_mobile[lang]) // could be "delete" string
      }
    }
  }

  return form
}

const defaultPreview = {
  logo: null,
  logo_png: null,
  logo_svg: null,
  logo_svg_raw: null,
  logo_file: null,
  banners: {},
  sliders: {}
}

const state = () => ({
  loaded: {
    brands: false,
    industries: false,
    platforms: false,
    socials: false,
  },
  brands: [],
  industries: [],
  paletteIndex: -1,
  logoIndex: -1,
  platforms: [],
  socials: [],
  list: {
    items: [],
    total: 0,
    page: 0,
  },
  current: null,
  slides: [],
  form: { ...defaultSiteForm },
  preview: { ...defaultPreview },
  languages: ['en', 'us', 'ru'],
  types: ['mobile', 'desktop'],
  languages_order: ['en', 'us', 'de', 'ru'],
  latestBuild: null,
  filter: {
    stage: 'prod',
    withDeleted: false,
    search: '',
    page: 1,
    size: 10,
    order: 'desc',
    sort: 'api_created',
  }
});

const getters = {
  languagesOrder: state => state.languages_order,
  getBrands: state => state.brands,
  getIndustries: state => state.industries,
  getPlatforms: state => state.platforms,
  getSocials: state => state.socials,
  getLatestBuild: state => state.latestBuild,
  allSites: state => state.list.items || [],
  totalCount: state => state.list.total || 1,
  currentSite: state => state.current,
  currentSlides: state => state.slides,
  form: state => state.form,
  preview: state => state.preview,
  languages: state => state.languages,
  formLangs: state => state.form.langs.slice(), // .sort((a, b) => state.form.base_lang === a ? -1 : 1)
  colors: state => state.form.colors || {},
  colorsHistory: state => Object.values(state.form.colors)
    .concat([
      state.form.design_navbar.color,
      state.form.design_navbar.color_active,
      state.form.design_navbar.background,
      state.form.design_navbar.gradient_to,
      state.form.design_buttons.color,
      state.form.design_buttons.color_active,
      state.form.design_buttons.background,
      state.form.design_buttons.gradient_to,
      state.form.design_bottom_navbar.color,
      state.form.design_bottom_navbar.color_active,
      state.form.design_bottom_navbar.background,
      state.form.design_bottom_navbar.gradient_to,
    ])
    .filter(c => c)
    .map(c => c.toLowerCase())
    .filter((c, i, a) => a.indexOf(c) === i),
  formBannersGallery: (state, getters) => {
    const banners = []
    for (const lang of getters.formLangs) {
      for (const type of state.types) {
        if (state.preview.banners[type + '_' + lang]) {
          banners.push({
            type,
            lang,
            src: state.preview.banners[type + '_' + lang]
          })
        }
      }
    }
    return banners
  },
  designNavbar: state => ({ ...state.form.design_navbar }),
  designBottomNavbar: state => ({ ...state.form.design_bottom_navbar }),
  designButtons: state => ({ ...state.form.design_buttons }),
  industryData: state => state.industries.find(i => i.code === state.form.industry),
  isLoaded: state => (type) => !!state.loaded[type],
  selectedPalette: state => state.paletteIndex,
  selectedLogo: state => state.logoIndex,
  sitesFilter: state => state.filter,
}

const actions = {
  makeFormData({ state }, payload = null) {
    const form = new FormData()
    const data = payload || state.form;
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        if (data[key] && data[key] instanceof File) {
          form.append(key, data[key], data[key].name)
        } else if (typeof data[key] === 'object' && !Array.isArray(data[key]) && data[key] !== null) {
          for (const subKey in data[key]) {
            if (data[key].hasOwnProperty(subKey)) {
              if (data[key][subKey] instanceof File) {
                form.append(`${key}[${subKey}]`, data[key][subKey], data[key][subKey].name)
              } else if (data[key][subKey] === '' && data.defaults && data.defaults[key] && data.defaults[key][subKey]) {
                form.append(`${key}[${subKey}]`, data.defaults[key][subKey])
              } else {
                form.append(`${key}[${subKey}]`, data[key][subKey])
              }
            }
          }
        } else if (Array.isArray(data[key])) {
          data[key].forEach(item => {
            form.append(key, item)
          })
        } else {
          form.append(key, data[key])
        }
      }
    }
    for (const color in data.colors) {
      form.set(`${color}_color`, data.colors[color])
    }
    return form
  },
  async update({ commit, state }, formData) {
    return updateSite(state.form.id, formData).then(({ data }) => {
      commit('UPDATE_SITE', data)
      return data;
    }).catch(console.error)
  },
  async patch({ commit, state }, { id, ...data }) {
    return patchSite(id, data).then(({ data }) => {
      commit('UPDATE_SITE', data)
      return data;
    }).catch(console.error)
  },
  async findSite({ commit, state }, { id, api_id, set = true, force = false }) {
    let site = state.list.items.find(s => id ? s.id === id : s.api_id === api_id);
    if (!site || force) {
      const { data } = await getSite(id || api_id);
      if (data) {
        site = data
      }
    }
    if (set && site) {
      commit('SET_CURRENT_SITE', site);
    }
    return site;
  },
  async loadSites({ commit, state }) {
    try {
      let { data } = await getSites(state.filter);
      commit('SET_SITES', data)
    } catch (error) {
      console.error(error);
    }
  },
  async loadBrands({ commit, state }) {
    if (!state.loaded.brands) {
      return getBrands().then(({ data }) => {
        commit('SET_BRANDS', data)
        return data;
      }).catch(console.error)
    }
    return state.brands
  },
  async loadLatestBuild({ commit, state }, { force = false }) {
    if (!state.latestBuild || force) {
      return getLatestBuild().then(({ data }) => {
        commit('SET_LATEST_BUILD', data)
        return data;
      }).catch(console.error)
    }
    return state.latestBuild
  },
  async toggleLatestBuild({ commit, state }, { id, clean, error, repeat, hours, set = true }) {
    return toggleLatestBuild({ id, clean, error, repeat, hours })
      .then(({ data }) => {
        if (set) commit('SET_LATEST_BUILD', data)
        return data;
      }).catch(console.error)
  },
  async loadIndustries({ commit, state }) {
    // await new Promise((resolve) => setTimeout(resolve, 5000)) // just to test loading
    if (!state.loaded.industries) {
      await getIndustries().then(({ data }) => {
        commit('SET_INDUSTRIES', data)
      }).catch(console.error)
    }
    return state.industries
  },
  loadPlatforms({ commit, state }) {
    if (!state.loaded.platforms) {
      return getPlatforms().then(({ data }) => {
        commit('SET_PLATFORMS', data)
        return data
      }).catch(console.error)
    }
    return state.platforms
  },
  loadSocials({ commit, state }) {
    if (!state.loaded.socials) {
      getSocials().then(({ data }) => {
        commit('SET_SOCIALS', data)
      }).catch(console.error)
    }
  },
  updateSiteStatus({ commit }, data) {
    commit('UPDATE_SITE_STATUS', data)
  },
  updateSiteImportTime({ commit }, data) {
    commit('UPDATE_SITE_IMPORT_TIME', data)
  },
  resetCurrentSite({ commit }) {
    commit('RESET_CURRENT_SITE')
  },
  resetForm({ commit }) {
    commit('RESET_SITE_FORM')
    commit('RESET_PREVIEW')
  },
  resetNavbarDesign({ commit, dispatch, getters }) {
    if (getters.selectedPalette !== -1 && getters.industryData && getters.industryData.gammas && getters.industryData.gammas.length) {
      dispatch('setNavbarDesign', {
        design: {
          navbar: getters.industryData.gammas[getters.selectedPalette].navbar || null
        }
      })
    } else {
      commit('RESET_NAVBAR_DESIGN')
    }
  },
  resetBottomNavbarDesign({ commit }) {
    commit('RESET_BOTTOM_NAVBAR_DESIGN')
  },
  resetButtonsDesign({ commit, dispatch, getters }) {
    if (getters.selectedPalette !== -1 && getters.industryData && getters.industryData.gammas && getters.industryData.gammas.length) {
      dispatch('setButtonsDesign', {
        design: {
          buttons: getters.industryData.gammas[getters.selectedPalette].buttons || null
        }
      })
    } else {
      commit('RESET_BUTTONS_DESIGN')
    }
  },
  setNavbarDesign({ commit }, { design }) {
    const navbar = design && design.navbar ? design.navbar : {}
    commit('SET_SITE_FORM', {
      design_navbar: {
        type: navbar.type || defaultNavbarDesign.type,
        background: navbar.background || defaultNavbarDesign.background,
        solid_blur: !!navbar.solid_blur,
        gradient_to: navbar.gradient_to || defaultNavbarDesign.gradient_to,
        gradient_angle: navbar.gradient_angle || defaultNavbarDesign.gradient_angle,
        texture_preview: navbar.texture_preview || defaultNavbarDesign.texture_preview,
        texture_file: navbar.texture_file || defaultNavbarDesign.texture_file,
        sticky: !!navbar.sticky,
        sticky_hideable: !!navbar.sticky_hideable,
        color: navbar.color || defaultNavbarDesign.color,
        color_active: navbar.color_active || defaultNavbarDesign.color_active,
        transparent: !!navbar.transparent,
        color_on_light: navbar.color_on_light || defaultNavbarDesign.color_on_light,
        color_on_dark: navbar.color_on_dark || defaultNavbarDesign.color_on_dark,
      },
    })
  },
  setBottomNavbarDesign({ commit }, { design }) {
    const bottomNavbar = design && design['bottom-navbar'] ? design['bottom-navbar'] : {}
    commit('SET_SITE_FORM', {
      design_bottom_navbar: {
        copy: bottomNavbar.copy || defaultBottomNavbarDesign.copy,
        hide_label: !!bottomNavbar.hide_label,
        type: bottomNavbar.type || defaultBottomNavbarDesign.type,
        background: bottomNavbar.background || defaultBottomNavbarDesign.background,
        solid_blur: !!bottomNavbar.solid_blur,
        gradient_to: bottomNavbar.gradient_to || defaultBottomNavbarDesign.gradient_to,
        gradient_angle: bottomNavbar.gradient_angle || defaultBottomNavbarDesign.gradient_angle,
        texture_preview: bottomNavbar.texture_preview || defaultBottomNavbarDesign.texture_preview,
        texture_file: bottomNavbar.texture_file || defaultBottomNavbarDesign.texture_file,
        color: bottomNavbar.color || defaultBottomNavbarDesign.color,
        color_active: bottomNavbar.color_active || defaultBottomNavbarDesign.color_active,
        transparent: !!bottomNavbar.transparent,
      }
    })
  },
  setButtonsDesign({ commit }, { design }) {
    const buttons = design && design.buttons ? design.buttons : {}
    commit('SET_SITE_FORM', {
      design_buttons: {
        type: buttons.type || defaultButtonsDesign.type,
        background: buttons.background || defaultButtonsDesign.background,
        gradient_to: buttons.gradient_to || defaultButtonsDesign.gradient_to,
        gradient_angle: buttons.gradient_angle || defaultButtonsDesign.gradient_angle,
        texture_preview: buttons.texture_preview || defaultButtonsDesign.texture_preview,
        texture_file: buttons.texture_file || defaultButtonsDesign.texture_file,
        color: buttons.color || defaultButtonsDesign.color,
        color_active: buttons.color_active || defaultButtonsDesign.color_active,
        border_radius: buttons.border_radius || defaultButtonsDesign.border_radius,
      }
    })
  },
  setSiteColors({ commit }, { colors }) {
    commit('SET_SITE_FORM', {
      colors: {
        ...defaultColors,
        primary: (colors && colors.primary) || defaultColors.primary,
        secondary: (colors && colors.secondary) || defaultColors.secondary,
        info: (colors && colors.info) || defaultColors.info,
        price: (colors && colors.price) || defaultColors.price,
        success: (colors && colors.success) || defaultColors.success,
        warning: (colors && colors.warning) || defaultColors.warning,
        danger: (colors && colors.danger) || defaultColors.danger,
      }
    })
  },
  setPalette({ dispatch, commit }, { palette, index = 0 }) {
    dispatch('setSiteDesign', {
      colors: palette.colors,
      design: {
        navbar: palette.navbar,
        buttons: palette.buttons,
      }
    })
    if (palette.logo && Object.values(palette.logo).filter(Boolean).length > 0) {
      dispatch('setSiteLogosFromUrl', palette.logo)
    }
    commit('SET_PALETTE', { index })
  },
  async setSiteDesign({ dispatch }, { colors, design }) {
    await dispatch('setSiteColors', { colors })
    dispatch('setNavbarDesign', { design })
    dispatch('setButtonsDesign', { design })
  },
  setSiteLogosFromUrl({ commit, getters }, { png, svg }) {
    const logos = {
      logo: svg || png || '',
      logo_png: png || '',
      logo_svg: svg || '',
      logo_svg_raw: ''
    }
    commit('SET_SITE_FORM', logos)
    commit('SET_PREVIEW', logos)

    const locale = Vue.$i18n ? Vue.$i18n.locale : 'en'
    const logoIndex = getters.industryData && Object.keys(getters.industryData.logos || {}).length > 0
      ? (getters.industryData.logos[locale] || getters.industryData.logos.en || []).findIndex(logo => logo === svg || logo === png)
      : -1
    commit('SET_SELECTED_LOGO', { index: logoIndex })
  },
  resetSiteDesign({ commit, dispatch, getters }) {
    commit('RESET_SITE_COLORS')
    commit('RESET_NAVBAR_DESIGN')
    commit('RESET_BOTTOM_NAVBAR_DESIGN')
    commit('RESET_BUTTONS_DESIGN')
    if (getters.selectedPalette !== -1 && getters.industryData && getters.industryData.gammas && getters.industryData.gammas.length) {
      dispatch('setPalette', {
        palette: getters.industryData.gammas[getters.selectedPalette],
        index: getters.selectedPalette
      })
    }
  },
  edit({ commit, dispatch }, site) {
    const banners = {}
    if (site.banners) {
      for (const type in site.banners) {
        for (const lang in site.banners[type]) {
          banners[`${type}_${lang}`] = site.banners[type][lang]
        }
      }
    }
    const sliders = (site.sliders || []).map(slide => {
      const file_desktop = {}
      const file_mobile = {}
      for (const lang of slide.langs) {
        file_desktop[lang] = null
        file_mobile[lang] = null
      }
      return {
        ...slide,
        new: false,
        file_desktop,
        file_mobile
      }
    })

    commit('SET_SITE_FORM', {
      id: site.id,
      api_id: site.api_id,
      domain: String(site.domain).replace('.instashop.io', '').replace('.powebrand.io', ''),
      domain_first_level: site.domain_first_level,
      name: site.name,
      username: site.instagram,
      primary_color: (site.colors && site.colors.primary) || defaultSiteForm.primary_color,
      secondary_color: (site.colors && site.colors.secondary) || defaultSiteForm.secondary_color,
      header_color: (site.colors && site.colors.header) || defaultSiteForm.header_color,
      icons_color: (site.colors && site.colors.icons) || defaultSiteForm.icons_color,
      primary_gradient_color: (site.colors && site.colors.primary_gradient) || defaultSiteForm.primary_gradient_color,
      info_color: (site.colors && site.colors.info) || defaultSiteForm.info_color,
      warning_color: (site.colors && site.colors.warning) || defaultSiteForm.warning_color,
      danger_color: (site.colors && site.colors.danger) || defaultSiteForm.danger_color,
      success_color: (site.colors && site.colors.success) || defaultSiteForm.success_color,
      price_color: (site.colors && site.colors.price) || defaultSiteForm.price_color,
      brands: site.brands || {},
      logo: site.logo || '',
      logo_png: site.logo_png || site.logo || '',
      logo_svg: site.logo_svg || '',
      langs: site.langs || defaultSiteForm.langs,
      base_lang: site.base_lang || defaultSiteForm.base_lang,
      sliders: sliders,
      splash: site.splash || '',
      feeds: site.feeds || {},
      stages: site.stages || {},
      splash_desktop: site.splash || '',
      influencer_name: site.owner ? site.owner.name : '',
      influencer_phone: site.owner ? site.owner.phone : '',
      influencer_instagram: site.owner ? site.owner.username : ''
    })
    commit('SET_PREVIEW', {
      logo: site.logo,
      logo_png: site.logo_png || site.logo,
      logo_svg: site.logo_svg,
      logo_file: site.logo_svg || site.logo_png || site.logo,
      banners
    })
    const logo = site.logo_svg || site.logo_png || site.logo
    if (logo) {
      commit('SET_SELECTED_LOGO', { index: -2 });
      if (logo.endsWith('.svg')) {
        dispatch('setSvgLogo', logo)
          .catch(() => {
            dispatch('setSvgLogo', logo.replace(/^https?:\/\/\S+\/img\/upload\//i, 'https://api.cardoapi.com/upload/'))
              .catch(console.error)
          })
      }
    }
    commit('SET_SITE_SLIDES', sliders)
    dispatch('setSiteDesign', site)
  },
  updateSiteColor({ commit }, { color, value }) {
    commit('UPDATE_SITE_COLOR', { color, value })
  },
  updateFormField({ commit }, { field, value, level = 'state' }) {
    commit('UPDATE_FORM_FIELD', { field, value, level })
  },
  updateForm({ commit }, form) {
    commit('SET_SITE_FORM', form)
  },
  uploadFile({ commit, dispatch }, { file, field, level = 'state' }) {
    dispatch('revokePreview', { field, level })
    if (file && file instanceof File && file.type.startsWith('image/')) {
      commit('SET_PREVIEW_FIELD', {
        level,
        field,
        value: URL.createObjectURL(file)
      })
      if (field === 'logo_svg' || file.type === 'image/svg+xml') {
        const reader = new FileReader()
        reader.onload = () => {
          commit('SET_PREVIEW_FIELD', {
            level,
            field: 'logo_svg_raw',
            value: reader.result
          })
        }
        reader.readAsText(file)
      }
    } else if (file && typeof file === 'string') {
      commit('SET_PREVIEW_FIELD', {
        level,
        field,
        value: file
      })
    }
    commit('UPDATE_FORM_FIELD', { level, field, value: file })
  },
  async setSvgLogo({ commit }, url) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open('GET', url, true);
      request.responseType = 'blob';
      request.onload = () => {
        const reader = new FileReader();
        reader.readAsText(request.response);
        reader.onload = () => {
          commit('SET_PREVIEW_FIELD', {
            field: 'logo_svg_raw',
            value: reader.result
          })
          resolve(reader.result)
        };
      };
      request.onerror = (e) => {
        reject(e)
      }
      request.send();
    })
  },
  removeFile({ commit, dispatch }, { field, level = 'state' }) {
    dispatch('revokePreview', { field, level })
    commit('UPDATE_FORM_FIELD', { level, field, value: 'delete' })
  },
  revokePreview({ state, commit }, { field = 'logo', level = 'state' }) {
    const preview = level === 'state' ? state.preview : state.preview[level]
    if (preview[field]) {
      URL.revokeObjectURL(preview[field])
      commit('SET_PREVIEW', level === 'state'
        ? { [field]: null }
        : { [level]: { ...state.preview[level], [field]: null } }
      )
      if (field === 'logo_svg') {
        commit('SET_PREVIEW', level === 'state'
          ? { logo_svg_raw: null }
          : { [level]: { ...state.preview[level], logo_svg_raw: null } }
        )
      }
    }
  },
  addSlide({ state, commit }) {
    const id = state.slides.length ? Math.max(...state.slides.map(slide => slide.id)) + 1 : 1
    commit('ADD_SLIDE', { id })
    return { id }
  },
  changeSlideImage({ state, commit }, { id, lang, file, type }) {
    if (file) {
      commit('SET_SLIDE_IMAGE', { id, lang, file, type })
    } else {
      commit('REMOVE_SLIDE_IMAGE', { id, lang, type })
    }
  },
  async updateSlide({ state, commit, dispatch }, slide) {
    const siteId = state.current.id
    const existed = state.slides.find(s => s.id === slide.id)
    if (!existed) {
      console.error('slide not found', slide.id)
      return
    }
    if (existed.is_new || existed.new) {
      return addSlide(siteId, convertSlideToFormData(slide, state.languages))
        .then(({ data }) => {
          console.log('slide added', slide.id, data)
          // commit('UPDATE_SITE_SLIDE', { id: slide.id, data })
          return data
        })
        .catch(console.error)
    } else {
      return updateSlide(siteId, slide.id, convertSlideToFormData(slide, state.languages))
        .then(({ data }) => {
          console.log('slide updated', slide.id, data)
          // commit('UPDATE_SITE_SLIDE', { id: slide.id, data })
          return data
        })
        .catch(console.error)
    }
  },
  async deleteSlide({ state, commit, dispatch }, id) {
    const slide = state.slides.find(slide => slide.id === id)
    if (slide) {
      if (!slide.new) {
        const deleted = await deleteSlide(state.current.id, slide.id)
          .catch(e => {
            console.error(e)
            return null
          })
        console.log('deleted site slide', deleted)
      }
      commit('DELETE_SITE_SLIDE', { id })
    }
    return slide
  },
  async refreshSiteData({ commit }, id) {
    return refreshSiteData(id)
      .then(({ data }) => {
        console.log('refresh', data);
        commit('UPDATE_SITE', data.site)
        return data.site
      })
      .catch(error => {
        console.error(error);
        return null
      })
  },
  async importSiteData({ commit }, id) {
    return importSiteData(id)
      .then(({ data }) => {
        console.log('import', data);
        commit('UPDATE_SITE', data.site)
        return data.site
      })
      .catch(error => {
        console.error(error);
      })
  },
  async deleteSite({ commit }, id) {
    commit('DELETE_SITE', { id })
    return deleteSite(id)
      .then(({ data }) => {
        return data
      })
      .catch(error => {
        console.error(error);
        return null
      })
  },

  async setProdMode({commit, dispatch}, id) {
    await siteToProd(id)
    commit('SET_PROD_MODE', id);
  },
}

const mutations = {
  SET_SITES(state, value) {
    state.list = value
  },
  SET_LATEST_BUILD(state, value) {
    state.latestBuild = value
  },
  SET_BRANDS(state, value) {
    state.brands = value
    state.loaded.brands = true
  },
  SET_INDUSTRIES(state, value) {
    state.industries = value
    state.loaded.industries = true
  },
  SET_PLATFORMS(state, value) {
    if (value && value.length) {
      if (!value.find(p => p.code === 'instagram')) {
        value.push({ code: 'instagram', launch_mode: ['shop', '3dshop'] })
      }
    }
    state.platforms = value
    state.loaded.platforms = true
  },
  SET_SOCIALS(state, value) {
    state.socials = value
    state.loaded.socials = true
  },
  UPDATE_SITE_STATUS(state, data) {
    if (data && data.id && data.status) {
      state.list.items = [...state.list.items.map(site => site.id !== data.id ? site : { ...site, status: data.status })]
    }
  },
  UPDATE_SITE_IMPORT_TIME(state, data) {
    if (data && data.id && data.date) {
      state.list.items = [...state.list.items.map(site => site.id !== data.id ? site : { ...site, last_import: data.date })]
    }
  },
  SET_CURRENT_SITE(state, value) {
    state.current = value;
  },
  RESET_CURRENT_SITE(state) {
    state.current = null
  },
  RESET_SITE_FORM(state) {
    state.form = {
      ...defaultSiteForm,
      colors: { ...defaultSiteForm.colors },
      design_navbar: { ...defaultNavbarDesign },
      design_bottom_navbar: { ...defaultBottomNavbarDesign },
      design_buttons: { ...defaultButtonsDesign },
      sliders: [...defaultSiteForm.sliders],
      brands: { ...defaultSiteForm.brands },
      langs: [...defaultSiteForm.langs],
      banners: { ...defaultSiteForm.banners }
    }
    state.slides = []
  },
  RESET_SITE_COLORS(state) {
    state.form = {
      ...state.form,
      colors: { ...defaultColors }
    }
  },
  RESET_NAVBAR_DESIGN(state) {
    state.form = {
      ...state.form,
      design_navbar: { ...defaultNavbarDesign }
    }
  },
  RESET_BOTTOM_NAVBAR_DESIGN(state) {
    state.form = {
      ...state.form,
      design_bottom_navbar: { ...defaultBottomNavbarDesign }
    }
  },
  RESET_BUTTONS_DESIGN(state) {
    state.form = {
      ...state.form,
      design_buttons: { ...defaultButtonsDesign }
    }
  },
  UPDATE_SITE_COLOR(state, { color, value }) {
    Vue.set(state.form.colors, color, value)
    if (state.hasOwnProperty(`${color}_color`)) {
      state[`${color}_color`] = value
    }
  },
  UPDATE_FORM_FIELD(state, { field, value, level = 'state' }) {
    if (level === 'state') {
      // Vue.set(state.form, field, value)
      state.form = { ...state.form, [field]: value }
    } else {
      state.form[level] = { ...state.form[level], [field]: value }
      // Vue.set(state.form[level], field, value)
    }
  },
  SET_SITE_FORM(state, form) {
    state.form = { ...state.form, ...form }
    // Object.assign(state.form, form)
  },
  RESET_PREVIEW(state) {
    state.preview = {
      ...defaultPreview,
      banners: { ...defaultPreview.banners },
      sliders: { ...defaultPreview.sliders }
    }
  },
  SET_PREVIEW(state, preview) {
    Object.assign(state.preview, preview)
  },
  SET_PREVIEW_FIELD(state, { field, value, level = 'state' }) {
    if (level === 'state') {
      Vue.set(state.preview, field, value)
    } else {
      Vue.set(state.preview[level], field, value)
    }
  },
  UPDATE_SITE(state, data) {
    state.list.items = [...state.list.items.map(site => site.id !== data.id ? site : data)]
  },
  DELETE_SITE(state, { id }) {
    state.list.items = [...state.list.items.filter(site => site.id !== id)]
    state.list.total = state.list.total - 1;
  },
  SET_SITE_SLIDES(state, slides = []) {
    state.slides = slides
  },
  UPDATE_SITE_SLIDE(state, { id, slide, index }) {
    state.slides = [
      ...state.slides.map((s, i) => id
        ? s.id !== id ? s : slide
        : i !== index ? s : slide
      )
    ]
  },
  ADD_SLIDE(state, slide) {
    state.slides = [
      ...state.slides,
      Object.assign({
        id: state.slides.length ? Math.max(...state.slides.map(slide => slide.id)) + 1 : 1,
        sort: state.slides.length ? Math.max(...state.slides.map(slide => slide.sort)) + 1 : 1,
        bg: false,
        langs: [],
        picture_desktop: {},
        picture_mobile: {},
        title: {},
        subtitle: {},
        text_over_title: {},
        file_desktop: {},
        file_mobile: {}
      }, slide, {
        new: true
      }),
    ]
  },
  DELETE_SITE_SLIDE(state, { id, index }) {
    state.slides = [
      ...state.slides.filter((slide, i) => id ? slide.id !== id : i !== index)
    ]
  },
  SET_SLIDE_IMAGE(state, { id, lang, file, type }) {
    state.slides = [
      ...state.slides.map(slide => slide.id !== id ? slide : {
        ...slide,
        [type === 'desktop' ? 'file_desktop' : 'file_mobile']: {
          ...slide[type === 'desktop' ? 'file_desktop' : 'file_mobile'],
          [lang]: file
        },
        [type === 'desktop' ? 'picture_desktop' : 'picture_mobile']: {
          ...slide[type === 'desktop' ? 'picture_desktop' : 'picture_mobile'],
          [lang]: URL.createObjectURL(file)
        }
      })
    ]
  },
  REMOVE_SLIDE_IMAGE(state, { id, lang, type }) {
    state.slides = [
      ...state.slides.map(slide => slide.id !== id ? slide : {
        ...slide,
        [type === 'desktop' ? 'file_desktop' : 'file_mobile']: {
          ...slide[type === 'desktop' ? 'file_desktop' : 'file_mobile'],
          [lang]: null // TODO: add delete here
        },
        [type === 'desktop' ? 'picture_desktop' : 'picture_mobile']: {
          ...slide[type === 'desktop' ? 'picture_desktop' : 'picture_mobile'],
          [lang]: null
        }
      })
    ]
  },
  SET_PALETTE(state, { index }) {
    state.paletteIndex = index
  },
  SET_SELECTED_LOGO(state, { index }) {
    state.logoIndex = index
  },
  RESET_ALL(state) {
    state.list = {
      items: [],
      page: 0,
      total: 0,
    }
    state.current = null
    state.slides = []
    state.form = { ...defaultSiteForm }
    state.preview = { ...defaultPreview }
    state.languages = ['en', 'us', 'ru']
    state.types = ['mobile', 'desktop']
    state.languages_order = ['en', 'us', 'de', 'ru']
    state.latestBuild = null
    state.filter = {
      stage: 'prod',
      withDeleted: false,
      search: '',
      page: 1,
      size: 12,
      order: 'desc',
      sort: 'api_created',
    }
  },
  SET_SITES_FILTER(state, {name, value}) {
    state.filter[name] = value;
  },
  SET_PROD_MODE(state, id) {
    const idx = state.list.items.findIndex(item => item.id === id)
    if (idx >= 0) {
      state.list.items[idx].test_mode = false;
    }
    if (state.current && state.current.id === id) {
      state.current.test_mode = false;
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
