import httpClient from './httpClient';

const END_POINT = '/api/brands';

const getList = () => httpClient.get(`${END_POINT}/list`);
const getInfo = (params) => httpClient.get(`${END_POINT}/info`, {
  params
});
const getStatistics = (params) => httpClient.get(`${END_POINT}/statistics`, {
  params
});
const getSteps = (params) => httpClient.get(`${END_POINT}/steps`, {
  params
});
const getProducts = (params) => httpClient.get(`${END_POINT}/products`, {
  params
});
const getSales = (params) => httpClient.get(`${END_POINT}/sales`, {
  params
});
const getShipments = (params) => httpClient.get(`${END_POINT}/shipments`, {
  params
});
const getTransactions = (params) => httpClient.get(`${END_POINT}/transactions`, {
  params
});
const getPayouts = (params) => httpClient.get(`${END_POINT}/payouts`, {
  params
});
const patchProduct = (id, data) => httpClient.patch(`${END_POINT}/products/${id}`, data, {
  headers: { 'Content-Type': 'application/json' }
});
const findSites = (params) => httpClient.get(`${END_POINT}/sites`, {
  params
});
const sendQuestion = (data) => httpClient.post(`${END_POINT}/question`, data, {
  headers: { 'Content-Type': 'application/json' }
});
const changeInfo = (data) => httpClient.post(`${END_POINT}/info`, data, {
  headers: { 'Content-Type': 'application/json' }
});
const stripeLink = ({ account, type, mode }) => httpClient.post(`${END_POINT}/stripe-link`, { account, mode, type }, {
  headers: { 'Content-Type': 'application/json' }
});
const connectStripe = (data) => httpClient.post(`${END_POINT}/stripe-connect`, data, {
  headers: { 'Content-Type': 'application/json' }
});
const stripeAccounts = (params) => httpClient.get(`${END_POINT}/stripe-accounts`, {
  params
})

export {
  getList,
  getInfo,
  getStatistics,
  getSteps,
  getProducts,
  getSales,
  getShipments,
  patchProduct,
  findSites,
  sendQuestion,
  changeInfo,
  stripeLink,
  connectStripe,
  getTransactions,
  getPayouts,
  stripeAccounts
};
