import {
  getList,
  getInfo,
  getProducts,
  getSales,
  getShipments,
  patchProduct,
  findSites,
  sendQuestion,
  getStatistics,
  changeInfo,
  getTransactions,
  getPayouts,
  stripeAccounts,
  getSteps
} from '@/api/brands.api';

const DEFAULT_STEPS = {
  loaded: false, // technical flag
  brand_id: 0, // brand id (technical)
  brand: false, // brand name, discount
  integration: false, // connected through shopify store
  warehouse: false, // warehouse address
  stripe: false, // stripe account
  products: false, // select products (in shopify/or here)
  approve: false, // we need to approve the brand,
  promotion: false, // promotion settings (optional)
}

const DEFAULT_STATISTICS = {
  loaded: false,
  brand_id: 0,
  products: {}, // for filters
  products_total: 0,
  products_synced: 0,
  products_errors: 0,
  shopify_products: 0,
  combined_products: 0,

  promotion_views: 0,
  promotion_likes: 0,
  promotion_posts: 0,
  promotion_be_posted: 0,
  promotion_saved: 0,
  promotion_saved_currency: '',

  sales_rows: 0,
  sales_products: 0,
  sales_sum: 0,
  sales_currency: 0,

  shipments_total: 0,
  shipment_to_ship_id: 0,
  shipments_products_total: 0,
  shipments_products_to_ship: 0,
  shipments_products_reserved: 0,
  shipments_products_finished: 0,
  shipments_currency: '',
  shipments_sum_paid: 0,
  shipments_sum_deposited: 0,
  shipments_sum_unpaid: 0,
  shipments_sum_on_its_way: 0,
  shipments_to_ship_paid: 0,
  shipments_to_ship_unpaid: 0,
  shipments_to_ship_sum: 0,

  shipments_filters: {},
  shipments_orders_to_ship: 0,
  shipments_orders_finished: 0,
  shipments_orders_new: 0,
  shipments_orders_reserve: 0,

  balance: 0,
  balance_id: 0,
  balance_total: 0,
  balance_active: 0,
  balance_paid: 0,
  balance_pending: 0,
  balance_currency: '',

  next_payment: 0,
  next_payment_date: '',

  transactions_total: 0,
  payouts_total: 0,
}

const paginateQuery = (queryParams = {}, pagination = {}, sort = {}) => {
  if (pagination) {
    queryParams.page = pagination.page || 1
    queryParams.limit = pagination.limit || 10
  }
  if (sort && sort.field && sort.order) {
    queryParams.sort = sort.field;
    queryParams.order = sort.order;
  }

  return queryParams
}

const state = {
  list: [],
  userBrand: null,
  userBrandStatistics: {
    ...DEFAULT_STATISTICS,
    products: {
      ...DEFAULT_STATISTICS.products
    }
  },
  userBrandSteps: {
    ...DEFAULT_STEPS
  },
  currentBrand: null,
  currentBrandStatistics: {
    ...DEFAULT_STATISTICS,
    products: {
      ...DEFAULT_STATISTICS.products
    }
  },
  currentBrandSteps: {
    ...DEFAULT_STEPS
  },
  products: {
    products: [],
    count: 0,
  },
  sales: {
    count: 0,
    products_count: 0,
    sum: 0,
    currency: '',
    products: []
  },
  pagination: {
    page: 1,
    perPage: 10
  },
  sort: {
    by: 'id',
    order: 'desc'
  },
  filter: {},
  loadedSites: [],
  shipments: {
    count: 0,
    products_count: 0,
    sum: {
      paid: 0,
      unpaid: 0
    },
    currency: '',
    shipments: []
  },
  transactions: [],
  payouts: []
}

const getters = {
  list: state => state.list,
  userBrand: state => state.userBrand,
  currentBrand: state => state.currentBrand,
  userBrandStat: state => state.userBrandStatistics,
  currentBrandStat: state => state.currentBrandStatistics,
  userBrandSteps: state => state.userBrandSteps,
  currentBrandSteps: state => state.currentBrandSteps,
  products: state => state.products.products,
  productsCount: state => state.products.count,
  sales: state => state.sales,
  salesProducts: state => state.sales.products,
  shipments: state => state.shipments,
  shipmentsList: state => state.shipments.shipments || [],
  shipmentById: state => id => state.shipments.shipments.find(p => String(p.id) === String(id)),
  findBrandByCode: state => code => state.list.find(brand => brand.code === code || brand.id === code),
  transactions: state => state.transactions,
  payouts: state => state.payouts
}

const actions = {
  async brandLoadHelper({ dispatch, commit }, { mutation, params }) {
    return getInfo(params)
      .then(({ data }) => {
        commit(mutation, { ...data, stripe_accounts: [], stripe_accounts_loading: !!data.stripe_account_id })
        return data
      })
      .then(brand => {
        if (brand.stripe_account_id) {
          stripeAccounts({ account: brand.stripe_account_id, token: brand.token })
            .then(({ data } ) => {
              commit(mutation, { ...brand, stripe_accounts: data, stripe_accounts_loading: false })
            })
            .catch(err => {
              console.error(err)
              commit(mutation, { ...brand, stripe_accounts: [], stripe_accounts_loading: false })
            })
        }
        return brand
      })
  },
  async loadBrandsList({ commit }){
    if (state.list.length) {
      return state.list
    }
    return getList()
      .then(({ data }) => {
        commit('SET_LIST', data.data)
        return data.data
      })
  },
  async loadUserBrand({ commit, dispatch }, { force = false } = {}) {
    if (state.userBrand && !force) {
      return state.userBrand
    }
    return dispatch('brandLoadHelper', { mutation: 'SET_USER_BRAND', params: {} })
  },
  async getBrandToken({ state, dispatch, getters }, { code }) {
    if (!state.list.length) {
      await dispatch('loadBrandsList')
    }
    const brand = getters.findBrandByCode(code)
    return brand ? brand.token : ''
  },
  async loadBrand({ commit, dispatch, getters }, { code, force = false}) {
    if (!state.list.length) {
      await dispatch('loadBrandsList')
    }
    const brand = getters.findBrandByCode(code)
    const params = { id: brand.id, code: brand.code, token: brand.token }
    if (brand) {
      return dispatch('brandLoadHelper', { mutation: 'SET_CURRENT_BRAND', params })
    }
    return Promise.reject('Brand not found')
  },
  loadStripeAccounts({ state, commit, dispatch }, { account, token }) {
    return stripeAccounts({ account, token })
  },
  async clearCurrentBrand({ commit }) {
    commit('SET_CURRENT_BRAND', null)
    commit('SET_CURRENT_BRAND_STAT', { ...DEFAULT_STATISTICS, brand_id: 0, loaded: false })
    commit('SET_CURRENT_BRAND_STEPS', { ...DEFAULT_STEPS, brand_id: 0, loaded: false })
  },
  async loadCurrentBrandStatistics({ commit, dispatch }, { id, token, code }) {
    if (!(id || token) && code) {
      try {
        const brand = await dispatch('loadBrand', { code })
        id = brand.id
        token = brand.token
      } catch (e) {
        return Promise.reject('Brand not found')
      }
    }
    if (state.currentBrandStatistics.loaded && state.currentBrandStatistics.brand_id === id) {
      return state.currentBrandStatistics
    }
    return getStatistics({ token })
      .then(({ data }) => {
        commit('SET_CURRENT_BRAND_STAT', { ...data, brand_id: id })
        return data
      })
  },
  async loadUserBrandStatistics({ commit }) {
      if (state.userBrandStatistics.loaded) {
        return state.userBrandStatistics
      }
      return getStatistics()
        .then(({ data }) => {
          commit('SET_USER_BRAND_STAT', data)
          return data
        })
  },
  async loadProducts({ commit }, { pagination, sort, filter, code, token, lang = 'en' }) {
    const queryParams = paginateQuery({ lang }, pagination, sort)
    if (filter) {
      queryParams.filter = filter
    }
    if (token) {
      queryParams.token = token
    } else if (code) {
      queryParams.token = await dispatch('getBrandToken', { code })
    }

    return getProducts(queryParams)
      .then(({ data }) => {
        commit('SET_PRODUCTS', data.data)
        return data.data
      })
  },
  async loadSales({ commit, dispatch }, { pagination, sort, token, code, lang = 'en' }) {
    const queryParams = paginateQuery({ lang }, pagination, sort)
    if (token) {
      queryParams.token = token
    } else if (code) {
      queryParams.token = await dispatch('getBrandToken', { code })
    }

    return getSales(queryParams)
      .then(({ data }) => {
        commit('SET_SALES', data.data)
        return data.data
      })
  },
  async loadTransactions({ commit, dispatch }, { pagination, sort, lang = 'en', token, code }) {
    const queryParams = paginateQuery({ lang }, pagination, sort)
    if (token) {
      queryParams.token = token
    } else if (code) {
      queryParams.token = await dispatch('getBrandToken', { code })
    }

    return getTransactions(queryParams)
      .then(({ data }) => {
        commit('SET_TRANSACTIONS', data)
        return data
      })
  },

  async loadPayouts({ commit, dispatch }, { pagination, sort, lang = 'en', token, code }) {
    const queryParams = paginateQuery({ lang }, pagination, sort)
    if (token) {
      queryParams.token = token
    } else if (code) {
      queryParams.token = await dispatch('getBrandToken', { code })
    }

    return getPayouts(queryParams)
      .then(({ data }) => {
        commit('SET_PAYOUTS', data)
        return data
      })
  },

  async loadShipments({ commit, dispatch }, { pagination, sort, filter, lang = 'en', token, code }) {
    const queryParams = paginateQuery({ lang }, pagination, sort)
    if (filter) {
      queryParams.filter = filter
    }
    if (token) {
      queryParams.token = token
    } else if (code) {
      queryParams.token = await dispatch('getBrandToken', { code })
    }

    return getShipments(queryParams)
      .then(({ data }) => {
        commit('SET_SHIPMENTS', data)
        return data
      })
  },
  async loadShipment({ state, commit, dispatch }, { id, code, token, lang = 'en' }) {
    const shipment = state.shipments.shipments.find(shipment => shipment.id === id)
    if (!shipment) {
      return dispatch('loadShipments', { lang, code, token })
        .then(() => state.shipments.shipments.find(shipment => shipment.id === id))
    }
    return shipment
  },
  async updateProduct({ commit }, { id, ...properties }) {
    commit('UPDATE_PRODUCT', { id, loading: true })
    return patchProduct(id, properties)
      .then(({ data }) => {
        commit('UPDATE_PRODUCT', { ...data.data, loading: false, id})
        return data.data
      })
      .catch(() => {
        commit('UPDATE_PRODUCT', { id, loading: false })
      })
  },
  /**
   * @param commit
   * @param state
   * @param product number
   * @param sites number[]
   */
  async getProductSites({ commit, state }, { product, sites }) {
    if (sites.every(siteId => state.loadedSites.find(site => String(site.id) === String(siteId)))) {
      return state.loadedSites.filter(site => sites.includes(site.id))
    }
    return findSites({ product, sites })
      .then(({ data }) => {
        commit('SET_LOADED_SITES', data.data)
        return data.data
      })
  },
  async sendQuestion({ commit }, { question, lang }) {
    return sendQuestion({ question, lang })
      .then(({ data }) => {
        return data
      })
  },
  async saveInfo({ commit, getters, dispatch }, {
    id,
    token,
    discount,
    feed,
    change_name,
    change_domain,
    brand,
    domain,
    address,
    zip,
    city,
    region,
    country,
    name,
    phone,
    email,
  }) {
    return changeInfo({
      id,
      token,
      discount,
      change_name,
      change_domain,
      brand,
      feed,
      domain,
      address,
      zip,
      city,
      region,
      country,
      contact: {
        name,
        phone,
        email
      }
    }).then(({ data }) => {
      if (getters.currentBrand && String(getters.currentBrand.id) === String(id)) {
        dispatch('brandLoadHelper', { mutation: 'SET_CURRENT_BRAND', params: { id, token } })
        // commit('SET_CURRENT_BRAND', { ...getters.currentBrand, ...data.info })
      } else if (getters.userBrand && String(getters.userBrand.id) === String(id)) {
        dispatch('loadUserBrand', { force: true })
        // commit('SET_USER_BRAND', { ...getters.userBrand, ...data.info })
      }
    })
  },
  async loadUserBrandSteps({ commit }) {
    if (state.userBrandSteps.loaded) {
      return state.userBrandSteps
    }
    return getSteps()
      .then(({ data }) => {
        commit('SET_USER_BRAND_STEPS', { ...DEFAULT_STEPS, ...data })
        return { ...DEFAULT_STEPS, ...data }
      })
  },
  async loadCurrentBrandSteps({ commit, dispatch }, { id, token, code}) {
    if (!(id || token) && code) {
      try {
        const brand = await dispatch('loadBrand', { code })
        id = brand.id
        token = brand.token
      } catch (e) {
        return Promise.reject('Brand not found')
      }
    }
    if (state.currentBrandSteps.loaded && state.currentBrandSteps.brand_id === id) {
      return state.currentBrandSteps
    }
    return getSteps({ token })
      .then(({ data }) => {
        commit('SET_CURRENT_BRAND_STEPS', { ...data, brand_id: id })
        return data
      })
  },
}

const mutations = {
  SET_LIST(state, list) {
    state.list = list
  },
  SET_USER_BRAND(state, brand) {
    state.userBrand = brand
  },
  SET_CURRENT_BRAND(state, brand) {
    state.currentBrand = brand
  },
  SET_PRODUCTS(state, { products, count }) {
    state.products = { products: products.map(p => ({ ...p, loading: false })), count }
  },
  SET_SALES(state, { count, products_count, sum, currency, products }) {
    state.sales = { count, products_count, sum, currency, products }
  },
  UPDATE_PRODUCT(state, { id, ...properties }) {
    const product = state.products.products.find(product => product.id === id)
    if (product) {
      Object.assign(product, properties)
    }
  },
  SET_LOADED_SITES(state, sites) {
    state.loadedSites = [
      ...state.loadedSites,
      ...sites.filter(site => !state.loadedSites.find(loadedSite => loadedSite.id === site.id))
    ]
  },
  SET_CURRENT_BRAND_STAT(state, statistics) {
    state.currentBrandStatistics = {
      ...state.currentBrandStatistics,
      ...statistics,
      loaded: true
    }
  },
  SET_USER_BRAND_STAT(state, statistics) {
    state.userBrandStatistics = {
      ...state.userBrandStatistics,
      ...statistics,
      loaded: true
    }
  },
  SET_TRANSACTIONS (state, transactions) {
    state.transactions = transactions
  },
  SET_PAYOUTS (state, payouts) {
    state.payouts = payouts
  },
  SET_SHIPMENTS(state, { count, products_count, sum, currency, buyings }) {
    state.shipments = {
      count,
      products_count,
      sum: {
        ...state.shipments.sum,
        ...sum
      },
      currency,
      shipments: [...buyings]
    }
  },
  RESET_ALL(state) {
    state.userBrand = null
    state.currentBrand = null
    state.currentBrandStatistics = { ...DEFAULT_STATISTICS, products: { ...DEFAULT_STATISTICS.products } }
    state.userBrandStatistics = { ...DEFAULT_STATISTICS, products: { ...DEFAULT_STATISTICS.products } }
    state.products = {
      products: [],
      count: 0,
    }
    state.pagination = {
      page: 1,
      perPage: 10
    }
    state.sort = {
      by: 'id',
      order: 'desc'
    }
    state.filter = {}
    state.loadedSites = []
  },
  SET_USER_BRAND_STEPS(state, steps) {
    state.userBrandSteps = { ...steps, loaded: true }
  },
  SET_CURRENT_BRAND_STEPS(state, steps) {
    state.currentBrandSteps = { ...steps, loaded: true }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
